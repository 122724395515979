import { addPageAction, setCustomAttribute } from '../utils/new-relic';

const COHESION_PERFORMANCE_KEYS = [
  'connectEnd',
  'connectStart',
  'domComplete',
  'domContentLoadedEventEnd',
  'domContentLoadedEventStart',
  'domInteractive',
  'domLoading',
  'domainLookupEnd',
  'domainLookupStart',
  'fetchStart',
  'loadEventEnd',
  'loadEventStart',
  'navigationStart',
  'redirectEnd',
  'redirectStart',
  'requestStart',
  'responseEnd',
  'responseStart',
  'secureConnectionStart',
  'unloadEventEnd',
  'unloadEventStart',
];

export default ({ cohesion, _Tagular, performance }) => {
  try {
    // Tagular IDs
    cohesion('tagular:ready', () => {
      const { anonymousId, sessionId } = _Tagular.webContext;
      setCustomAttribute('cohesion.anonymousId', anonymousId);
      setCustomAttribute('cohesion.sessionId', sessionId);
    });

    // Preamp events
    cohesion('preamp:error', (error) => addPageAction('preampError', { error }));
    cohesion(
      'preamp:done',
      ({ decisionId, experienceNumber, experienceName }) => {
        addPageAction('preampDone', decisionId);
        setCustomAttribute('cohesion.preampExperience', experienceNumber);
        setCustomAttribute('cohesion.experienceName', experienceName);
      },
    );

    // Send performance data to New Relic
    if (
      typeof performance !== 'undefined'
      && typeof performance.timing !== 'undefined'
    ) {
      // Have to manually iterate over a list of keys since the provided
      // object does not implement Object.keys
      COHESION_PERFORMANCE_KEYS.forEach((key) => {
        // Remove methods that may be attached to
        // `performance` object
        if (
          typeof performance.timing[key] !== 'string'
          && typeof performance.timing[key] !== 'number'
        ) {
          return;
        }

        setCustomAttribute(
          `cohesion.performance.${key}`,
          performance.timing[key],
        );
      });
    }
  } catch (error) {
    addPageAction('cohesionError', { error });
  }
};
