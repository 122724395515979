const handlePopupOpen = () => {
  // grab all the tile triggers
  const getTileButtons = document.querySelectorAll('.js-tile-click');

  if (getTileButtons.length < 1) {
    return;
  }

  getTileButtons.forEach((element) => {
    // iterate through tile triggers and add event listner to them
    element.addEventListener('click', (e) => {
      // grab the parents classes and combine them to make a selector
      const parentSelector = e.target.closest('section').className.split(' ').join('.');
      // grab the text block from the preamp asset to inject into the popup
      document.querySelector(`.${parentSelector} .card-back`).style.display = 'block';
      const dataPosition = e.target.getAttribute('data-position');
      const dataLocation = e.target.getAttribute('data-location');

      document.querySelector('body').style.overflow = 'hidden';

      window.tagular('beam', {
        '@type': 'redventures.usertracking.v3.ElementClicked',
        webElement: {
          location: dataLocation,
          position: dataPosition,
          text: 'Open',
        },
        actionOutcome: e.target.getAttribute('data-actionOutcome'),
        // correlationId: sas.cid.getCid() + '_' + eventCorrelationId,
      });
    });
  });
};

// how we close the popup.
const handlePopupClose = () => {
  const closeModal = document.querySelectorAll('.js-close-modal');
  if (closeModal.length < 1) {
    return;
  }

  // // grab the close button and add listner to it
  closeModal.forEach((element) => {
    element.addEventListener('click', (e) => {
      // hide the popup, reset the html for the next one
      // and remove the scroll inhibitor from the body

      e.target.closest('.card-back').style.display = 'none';
      document.querySelector('body').style.overflow = 'visible';

      window.tagular('beam', {
        '@type': 'redventures.usertracking.v3.ElementClicked',
        webElement: {
          location: e.target.parentElement.getAttribute('data-location'),
          position: e.target.parentElement.getAttribute('data-position'),
          text: 'Close',
        },
        actionOutcome: e.target.parentElement.getAttribute('data-actionOutcome'),
      });
    });
  });
};

export { handlePopupOpen, handlePopupClose };
