// NOTE: Preference is to use the ES6 module approach to constructing your project JS
import searchFormActions from './partials/_search';
import bannerTransformation from './partials/_banner';
import sas from './partials/_sas';

window.cohesion('preamp:done', () => {
  sas.init(window._Beam);
  sessionStorage.setItem('preamp', 'done');
});

window.cohesion('preamp:error', () => {
  sas.init(window._Beam);
  sessionStorage.setItem('preamp', 'error');
});

window.setTimeout(() => {
  if (!('preamp' in sessionStorage)) {
    sas.init(window._Beam);
  }
}, 2000);

searchFormActions();

window.cohesion('ready', bannerTransformation);
