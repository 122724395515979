class Campaign {
  constructor() {
    this._partnerID = '101';
    this._marcomHostname = 'www.apple.com';
    this._defaultCid = 'apy-310-111111111111';
  }

  /**
   * generateUUID()
   * @param {int} a Used for some unknown recursive reason and should just be ignored.
   * @desc Returns an UUID. Function is borrowed from the Cohesion client JS
   */
  generateUUID(a) {
    return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.generateUUID); // eslint-disable-line no-bitwise
  }

  /**
   * generateClickId()
   * @desc Returns a newly generated ClickID.
   */
  generateClickId() {
    return `${this.generateUUID()}_${_Cohesion.instanceId}`; // eslint-disable-line no-undef
  }

  /**
   * getCid()
   * @desc Returns the current CID in the URL.
   */
  getCid() {
    return new URL(window.location.href).searchParams.get('cid') || this._defaultCid;
  }

  /**
   * isMarcomSite()
   * @param {string} url URL to be checked if it belongs to Marcom
   */
  isMarcomSite(url) {
    return new URL(url).hostname === this._marcomHostname;
  }

  /**
   * getReferrerSearchParam()
   * @param {string} url Check if link goes to a Marcom domain.
   * @param {string} clickId Generate a new clickId if one is not passed in.
   * @desc Returns the tokens' key-value pairs for the referrer query string param
   */
  getReferrerSearchParam(url, clickId = this.generateClickId(), customCid = false) { // eslint-disable-line no-undef
    // Check if destination url goes to Marcom
    const marcom = this.isMarcomSite(url);
    // Get the current cid from the URL or from the custom cid data attribute.
    // Note that cid can be modified elsewhere (deeplink Preamp assets)
    // so its important we grab the cid at the time of this function call.
    const cid = !customCid ? this.getCid() : customCid;

    const newReferrerKey = marcom ? 'rid' : 'referrer';

    const cidPair = marcom ? `287-cid=${cid}` : `cid=${cid}`;
    const clickIdPair = `ckid=${clickId}`;
    const partnerIdPair = `ck_pid=${this._partnerID}`;

    const newReferrerValue = `${cidPair}&${clickIdPair}&${partnerIdPair}`;

    const referrerSearchParam = { referrerKey: newReferrerKey, referrerValue: newReferrerValue };
    return referrerSearchParam;
  }

  /**
   * updateAnchorTags()
   * @desc Append our tokens as a query string param and set correlationID for anchor tag.
   */
  updateAnchorTags() {
    const cid = this.getCid();
    const anchorTags = document.querySelectorAll('a');

    anchorTags.forEach((tag) => {
      if (tag.hasAttribute('data-exclude-link')) {
        return;
      }
      // Get a new ClickID for this anchor tag
      const clickId = this.generateClickId();
      // Set the anchor tag's correlation ID
      tag.dataset.correlationid = `${cid}_${clickId}`;
      // check if there's a #
      // Referrer query string param with new clickId
      if (!tag.hasAttribute('data-noUrlAlteration')) {
        const { referrerKey, referrerValue } = this.getReferrerSearchParam(tag.href, clickId, tag.dataset.customCid);
        // Update the anchor tag's href with referrer query string param
        const newHref = new URL(tag.href);
        newHref.searchParams.set(referrerKey, referrerValue);

        tag.href = newHref.toString();
        tag.dataset.externalurl = newHref.toString();

        const redirectAttribute = tag.getAttribute('data-redirect');

        if (redirectAttribute === 'true') {
          // append param to end of newHref string
          tag.href = `${newHref.toString()}&start=false`;
          tag.dataset.externalurl = `${newHref.toString()}&start=false`;
        }
      } else {
        tag.dataset.externalurl = tag.href;
      }
    });
  }

  init() {
    this.updateAnchorTags();
  }
}

export default Campaign;
