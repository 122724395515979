import { addPageAction } from '../utils/new-relic';

// when a click happens that is a cta, we check if the id is for the
// localnav and fire one event, or fire another if it's a section cta
const sendNewRelicClick = (e) => {
  e.preventDefault();

  if (e.target.getAttribute('id') === 'localnav-cta') {
    addPageAction('ctaClickLocalNav', { url: e.target.href });
  } else {
    addPageAction('ctaClickSection', { url: e.target.href });
  }

  window.location.href = e.target.href;
};

// grab all the links with the js-cta-link class  and add an eventlistner
// on click to them all.
const ctaListner = () => {
  document.querySelectorAll('.js-cta-link').forEach((link) => {
    link.addEventListener('click', sendNewRelicClick);
  });
};

// trying to figure out how to check the cta for the correct CID'a and UUID
// const sendNewRelicClick = (e) => {
//     e.preventDefault();
//     const url = (decodeURIComponent(e.target.href
//     .replace('referrer=','').split('?')[1])).split('&');
//    // console.log('url',url)

//     url.forEach((node, i) => {
//         if (node.includes('cid')) {
//             checkCid(node.split('='));
//         } else if (node.includes('ckid')) {
//           //  console.log(i, node.split('='));
//         }
//     })

// const dataObject = url.map(param => {
//     if (param.includes())
// })
// addPageAction('ctaClick', { error });
// }

export default ctaListner;
