/**
 * bannerTransformation()
 * @desc Performs the banner transition animation by measuring for
 * the margin first then applying styling
 */
const bannerTransformation = () => {
  // check to see if the banner for the default page is still there after cohesion is done
  const banners = document.querySelectorAll('.default-section-banner__banner');

  // if we don't have the default banner, don't do anything.
  if (banners.length === 0) {
    return;
  }

  const banner = document.querySelector('.default-section-banner__banner'); // the whole banner, top to bottom

  // make sure we have a banner before we do anything
  if (banner !== undefined && banner !== null && banner.offsetHeight !== 0) {
    const heroLogo = document.querySelector('.hero__logo'); // grab the logo in the hero
    // grab the cta in the banner
    // const bannerLink = document.querySelector('.default-section-banner__cta');
    let logoMargin = getComputedStyle(heroLogo).marginTop;// get top margin

    // build new top margin
    logoMargin = `${parseInt(logoMargin, 0) + banner.offsetHeight}px`;

    // apply new top margin
    heroLogo.style.marginTop = `${logoMargin}`;

    /* trigger for the transformations to take place on the banner to show it after 1.5 seconds */
    // setTimeout(() => {
    //   banner.style.backgroundColor = '#fff';
    //   banner.style.color = '#333';
    //   banner.style.transform = 'translateY(0)';
    //   bannerLink.style.color = '#0071e3';
    // }, 1500);
  }
};

export default bannerTransformation;
