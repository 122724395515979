import Campaign from './_cid';
import analytics from './_analytics';
import ElementViewedDurationEvent from './_custom-events';
import sosumi from './_sosumi';
import newRelicCohesion from './_newrelic';
import ctaListner from './_newrelicCta';
import { handlePopupOpen, handlePopupClose } from './_popup';

const cid = new Campaign();

// run beam and setup tagular eventing
const loadAnalytics = (beam) => {
  beam.load(analytics, {}).init();
};

// run dynamic sosumi for disclosures
const initSosumi = () => {
  sosumi.init();
};

const reloadSosumi = () => {
  initSosumi();
};

const sas = {
  cid,
  analytics: loadAnalytics,
  sosumi: initSosumi,
  reloadSosumi,
  viewedDuration: ElementViewedDurationEvent,
  init: (beam) => {
    cid.init();
    initSosumi();
    loadAnalytics(beam);
  },
};

// kick off New Relic events
newRelicCohesion(window);
ctaListner();

window.cohesion('preamp:done', () => {
  handlePopupOpen();
  handlePopupClose();

  const customEvent = new ElementViewedDurationEvent();
  customEvent.init();
});

// need to handle this better, would rather hvae try catch , and on fail pass event to NR
window.cohesion('preamp:error', () => {
  handlePopupOpen();
  handlePopupClose();

  const customEvent = new ElementViewedDurationEvent();
  customEvent.init();
});

export default sas;
