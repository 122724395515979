const searchFormActions = () => {
  // ============================================
  // The below is called when the form is submitted. It fires off the tagular event so we can
  // log what is entered into the serarch form.
  // ============================================

  const tagularFormSubmit = (target) => {
    // grab our input field
    const formData = target.querySelector('.js-searchform-input');

    // submit information to tagular, thankfull tagular is attahced to the window so we can grab
    // it there.
    window.tagular('beam', 'FormSubmitted', {
      '@type': 'redventures.usertracking.v3.FormSubmitted',
      formContext: {
        formType: formData.getAttribute('data-formtype'),
        formName: formData.getAttribute('data-formname'),
        formId: formData.getAttribute('data-formid'),
      },
      field: [{
        fieldType: 'search',
        fieldName: formData.getAttribute('data-formname'),
        fieldValue: formData.value,
      }],
    });
  };

  // Once we submit a search and we hear the event, we come here. We first
  // stop the default event from firing. We then
  // grab the input selector's value followed up by creating an href for us
  // to click. Once we add the href to the link,
  // we programactially click it.

  const submitSearch = (e) => {
    e.preventDefault();

    // we need to pull out the target/context of the event so we can use this process on multiple
    // spots.
    const { target } = e;
    // fire the tagular event so we can track what was searched.
    tagularFormSubmit(target);

    const searchValue = target.querySelector('.js-searchform-input').value;
    const submitSearchLink = document.createElement('a');
    submitSearchLink.href = `https://www.apple.com/us/search/${searchValue}?`;
    submitSearchLink.click();
  };

  // Once we hear the event for the input clear, we trigger clearSearchInput,
  // This will stop the event from firing, grab the query selector and then
  // set the value to an empty string to clear the field.
  const clearSearchInput = (e) => {
    e.preventDefault();

    document.querySelector('#searchform-input').value = '';
  };

  // ============================================
  // Watching for events on the 404 page search form
  // ============================================

  // grabbing the dom elements we need to listen to
  const initSearchForm = () => {
    const searchForm = document.querySelectorAll('.js-searchform-form');
    const resetButton = document.querySelector('#searchform-reset');

    // we get multiple query selectors on some pages for the search form, so we need to make
    // sure we are watching them all for submit.
    searchForm.forEach((element) => {
      if (element !== null && element !== undefined) element.addEventListener('submit', submitSearch, false);
    });

    if (resetButton !== null && resetButton !== undefined) resetButton.addEventListener('click', clearSearchInput, false);
  };
  return initSearchForm();
  // Start off by guarding the actual listerns themselves
  // If we have the dom element, we add a listner. First one
  // listens for the submit search, second on watches
  // for the input clear button.=
};

export default searchFormActions;
