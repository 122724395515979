export default {
  '.js-element-link': {
    '@type': 'ElementClicked',
    webElement: {
      location: 'data.location',
      text: 'data.text',
      position: 'data.position',
    },
    correlationId: 'data.correlationid',
    outboundUrl: 'data.externalurl',
  },
  '.js-extended-element-link': {
    '@type': 'ElementClicked',
    webElement: {
      elementType: 'data.elementtype',
      location: 'data.location',
      position: 'data.position',
      text: 'data.text',
    },
    actionOutcome: 'data.actionoutcome',
    outboundUrl: 'data.externalurl',
    correlationId: 'data.correlationid',
  },
  '.js-element-view': {
    '@type': 'ElementViewed',
    webElement: {
      location: 'data.location',
      position: 'data.position',
    },
  },
};
