class ElementViewedDurationEvent {
  constructor() {
    // Observers that are active
    this.observers = new Set();
    // Elements to be tracked
    this.observedElements = document.querySelectorAll('.js-element-view');
    // Set of visible elements we want to track
    this.visibleElements = new Set();
    // Used to temporarily store the list of visible elements
    // while the document is not visible (for example, if the user has tabbed to another page).
    this.previouslyVisibleElements = null;
    // Hardcoded marks in seconds for when we want to fire the Tagular event
    this.pingThresholds = [1, 2, 3, 4, 5, 7, 10, 15, 20, 30, 45];
    // Default percentage of an element required to be in the viewport to be considered visible
    this.defaultVisibleRatio = 0.75;
    // Initializes the total view time as 0 and last view started time as 0
    this.totalViewTime = 0;
    this.lastViewStarted = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  tagularEvent(element) {
    window.tagular('beam', {
      '@type': 'apple.ElementViewedDuration.v1',
      webElement: {
        location: element.dataset.location,
        position: element.dataset.position,
      },
      viewedDuration: parseInt(this.totalViewTime, 10),
    });
  }

  updateElementTimer(element) {
    const lastStarted = this.lastViewStarted;
    const currentTime = performance.now();
    // Check if timer is running and therefore the element is visible
    if (lastStarted) {
      const diff = currentTime - lastStarted;
      this.totalViewTime = parseFloat(this.totalViewTime) + diff;
      // Use parseInt since the pingCount data attribute is a string
      const pingCount = parseInt(element.dataset.pingCount, 10);
      // The next time the event should fire for the event
      const lastPinged = this.pingThresholds[pingCount] * 1000;
      // Check if we've reached that time
      if (this.totalViewTime > lastPinged) {
        element.dataset.pingCount = pingCount + 1;
        // Tagular event fires here
        this.tagularEvent(element);
      }
    }
    this.lastViewStarted = currentTime;
  }

  handleRefreshInterval() {
    this.visibleElements.forEach((element) => {
      this.updateElementTimer(element);
    });
  }

  // Addresses the scenario where the user tabs back and forth between tabs
  handleVisibilityChange() {
    if (document.hidden) {
      if (!this.previouslyVisibleElements) {
        // This is reached when the user has switched to another tab
        // Save the set of visible elements
        this.previouslyVisibleElements = this.visibleElements;
        // Empty the set so the elements won't be treated as visible
        this.visibleElements = [];
        this.previouslyVisibleElements.forEach((element) => {
          // Set the element's timer to when it became invisible
          this.updateElementTimer(element);
          // Set to 0 to indicate that the element's timer is not runnin
          this.lastViewStarted = 0;
        });
      }
    } else {
      // This is reached when the user has switched back from another tab
      // conditional here is to guard against opening in a new tab
      if (!this.previouslyVisibleElements) {
        this.previouslyVisibleElements = this.visibleElements;
      }
      this.previouslyVisibleElements.forEach(() => {
        // Set the element's timer to the current document's time
        this.lastViewStarted = performance.now();
      });
      // Treat the elements as visible again
      this.visibleElements = this.previouslyVisibleElements;
      this.previouslyVisibleElements = null;
    }
  }

  handleViewportResize() {
    this.deleteObservers();
    this.createObservers();
  }

  intersectionCallback(entries, observer) {
    entries.forEach((entry) => {
      const element = entry.target;
      // Either 75% or an unique ratio if element is too large
      const elementRatio = observer.thresholds[1];
      if (entry.isIntersecting
        && entry.intersectionRatio >= elementRatio
        && this.visibleElements.size !== undefined) {
        // This is reached when at least 75% of the element becomes visible
        // Start its timer
        this.lastViewStarted = entry.time;
        // Add the element to the list of visible elements
        this.visibleElements.add(element);
      } else if (!entry.isIntersecting && this.visibleElements.size !== undefined) {
        // This is reached when the element becomes not visible
        // i.e the element is completely out of the viewport
        // Removed the element from the list of visible elements
        this.visibleElements.delete(element);
      }
    });
  }

  addDataAttributes() {
    this.observedElements.forEach((element) => {
      // Number of times the event has been fired for the element
      element.dataset.pingCount = 0;
    });
  }

  deleteObservers() {
    this.observers.forEach((observer) => {
      observer.disconnect();
    });
    this.observers = new Set();
  }

  createObservers() {
    this.observedElements.forEach((element) => {
      const elementHeight = element.getBoundingClientRect().height;
      const viewportHeight = window.innerHeight;
      let elementVisibleRatio = this.defaultVisibleRatio;
      // Check if 75% of the element's height is greater than the viewport's height
      if ((this.defaultVisibleRatio * elementHeight) > viewportHeight) {
        // Element is too large for the viewport with the current threshold definition
        // Update it to be visible if the element takes up at least 75% of the viewport
        elementVisibleRatio = (viewportHeight * this.defaultVisibleRatio) / elementHeight;
      }
      const options = {
        root: null,
        rootMargin: '0px',
        // Set the threshold to fire the observer's callback when the targeted element:
        // - becomes completely obscured or first starts to become obscured (0.0)
        // - becomes 75% (or unique ratio set above) visible in either direction (.75 or unique)
        threshold: [0.0, elementVisibleRatio],
      };
      // Create observer for each element since they may have unique ratios for their visibility
      const observer = new IntersectionObserver(this.intersectionCallback.bind(this), options);
      // Start observing the element
      observer.observe(element);
      this.observers.add(observer);
    });
  }

  init() {
    if (window.location.origin === 'http://dev-apply.applecard.apple.com:4000') {
      return;
    }

    // Add the data attributes used for tracking to the elements to be observed
    this.addDataAttributes();
    // Event listener for when the viewport resizes (portrait to landscape, etc.)
    window.addEventListener('resize', this.handleViewportResize.bind(this), false);
    // Event listener for pausing timers while the page is tabbed out
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);
    // Refresh the timers
    window.setInterval(this.handleRefreshInterval.bind(this), 500);
    // Init the intersection observers
    this.createObservers();
  }
}

export default ElementViewedDurationEvent;
