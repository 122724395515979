/** Class that sets sosumi references in dom */
class Sosumi {
  constructor() {
    this.elements = null;
    this.disclosures = {}; // Key/Value pairing for sosumiRef + Dislcosure Copy
    this.disclosuresList = document.querySelector('.ac-gf-sosumi ol');
    this.hyperlinkedElements = null;
  }

  /**
   * get the instances of sosumi disclosures
   */
  getInstances() {
    this.elements = document.querySelectorAll('ref[data-sosumi-ref]');
    if (this.elements.length === 0) {
      return;
    }

    this.elements.forEach((ref) => {
      const key = ref.dataset.sosumiRef;
      const isEditable = ref.hasAttribute("isEditable");

      // Handling for editable disclosures
      if (isEditable) {
        const { sosumiRef } = ref.dataset;
        const sosumiCopy = ref.innerHTML;

        window.sas.disclosures[sosumiRef] = sosumiCopy;
      }

      if (!Object.prototype.hasOwnProperty.call(this.disclosures, key)) {
        this.disclosures[key] = window.sas.disclosures[key];
      }

    });
  }

  setFooter() {
    // check if we have any disclosures in the list
    if (this.disclosuresList === null || this.disclosuresList === undefined) {
      return;
    }

    // loop through existing list and empty it.
    while (this.disclosuresList.firstChild) {
      this.disclosuresList.removeChild(this.disclosuresList.firstChild);
    }

    // loop through discovered disclosures and create new listItems to
    // append to the disclosuresList
    const disclosures = Object.entries(this.disclosures);

    disclosures.forEach((disclosure) => {
      const [key] = disclosure;
      if (Object.prototype.hasOwnProperty.call(this.disclosures, key)) {
        const listItem = document.createElement('li');
        listItem.innerHTML = this.disclosures[key];
        listItem.setAttribute('id', key);
        this.disclosuresList.appendChild(listItem);
      }
    });
  }

  /**
   * set each Reference that corresponds to the data-sosumi value of each respective
   * disclosure
   */
  setReferences() {
    let disclosureCount = 0;
    // Handle annotation setting for hyperlinked disclosures.
    Object.values(this.elements).forEach((el) => {
      const references = Array.from(document.querySelectorAll(`ref[data-sosumi-ref="${el.dataset.sosumiRef}"]`));

      /**
      * check to make sure we don't up the count if we have an empty array.
      * this happens if we use duplicate disclosures
      */
      if (references.length > 0) {
        // eslint-disable-next-line no-param-reassign
        disclosureCount += 1;
      }

      references.forEach((ref, i) => {
        // TODO: Remove above functionality ass this will be global hyperlinked functionality
        if (i === 0 || ref.hasAttribute('data-sosumi-show')) {
          ref.outerHTML = `<a href='#${ref.dataset.sosumiRef}' class="hyperlinked-annotation" data-tagular-event="ElementClicked" data-elementtype="ANNOTATION"
          data-element="ElementClicked"
          data-actionOutcome="INTERNALLINK"
          data-text="${ref.dataset.sosumiRef}">${disclosureCount}</a>`;
        } else {
          ref.outerHTML = ' ';
        };
      });
    });
  }

  // setMegaMerchantDisclosures() {
  //   // The key for the mega merchants disclosures list in the global disclosures object
  //   const megaMerchantParentDisclosureKey = 'mega-merchants-disclosures';
  //   // Grab all instances of mega merchant ref tags
  //   const megaMerchantRefs = document.querySelectorAll(`ref[data-sosumi-mega-merchants=${megaMerchantParentDisclosureKey}]`);
  //   // Proceed if at least one exists
  //   if (megaMerchantRefs.length > 0) {
  //     // Grab the first global footer disclosure 'Merchant offers may change at any time.'
  //     const firstGlobalDisclosure = document.querySelector('li[data-sosumi-global="global-7"]');
  //     // Create a span element containing an asterisk
  //     const asterisk = document.createElement('span');
  //     asterisk.innerText = '*';
  //     // Prepend the asterisk at the beginning of the global footer disclosure
  //     firstGlobalDisclosure.insertAdjacentElement('afterbegin', asterisk);

  //     // Array of all the mega merchant disclosure keys set in the global disclosure object
  //     const megaMerchantDisclosureKeys = window.sas.disclosures[megaMerchantParentDisclosureKey];
  //     // Array of all the disclosure keys that already exist in the global footer
  //     const activeDisclosureKeys = Object.keys(this.disclosures);

  //     // Iterate over the mega merchant disclosure keys array
  //     // Note: We need to reverse this array because
  //     // we insert each new element after the first global disclosure element
  //     megaMerchantDisclosureKeys.reverse().forEach((disclosureKey) => {
  //       // Check if the disclosure already exists in the global footer
  //       if (!activeDisclosureKeys.includes(disclosureKey)) {
  //         // Create a list item element for the disclosure
  //         const disclosureListItem = document.createElement('li');
  //         // Set the list item element's content to the disclosure value
  //         disclosureListItem.innerHTML = window.sas.disclosures[disclosureKey];
  //         disclosureListItem.className = disclosureKey;
  //         // Insert the list item element after the initial global footer disclosure.
  //         firstGlobalDisclosure.insertAdjacentElement('afterend', disclosureListItem);
  //       }
  //     });

  //     // Delete the empty ref tags
  //     megaMerchantRefs.forEach((ref) => {
  //       ref.outerHTML = '';
  //     });
  //   }
  // }

  setEventListeners() {
    this.hyperlinkedElements = document.querySelectorAll('.hyperlinked-annotation');

    Object.values(this.hyperlinkedElements).forEach((element) => {
      element.addEventListener('click', (e) => {
        window.tagular('beam', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: e.target.parentElement.getAttribute('data-elementtype'),
            text: e.target.parentElement.getAttribute('data-text'),
          },
          actionOutcome: e.target.parentElement.getAttribute('data-actionOutcome')
        });
      })
    });
  }

  /**
   * Kick the whole process off
   */
  init() {
    this.getInstances();
    this.setFooter();
    this.setReferences();
    this.setEventListeners();
  }
}

export default new Sosumi();
